<template>
  <PageWithHeader>
    <template #title>
      <t path="xmas_2">Christmas & New Year Gifts</t>
    </template>
    <div class="main-body">
      <img class="main-bg" src="/img/events/christmas_bg@2x.png"/>
      <div class="p-16">
        <t path="xmas_10" as="p" :args="{ gift: winGift }" v-if="isWin" class="c-white pb-16" style="line-height: 20px;">
          Congratulations, You got the Xiaomi Band!
          We will buy it from Amazon.in and send it to your address below, please fill it asap.
        </t>

        <div class="df-center mt-24 img-btn" style="background-image: url(/img/events/btn_bg@2x.png);">
          <t class="f-bold c-white pt-12" path="xmas_3">Gifts</t>
        </div>

        <div class="gifts-wrap py-16">
          <div :class="['gift-item df-middle mb-8 p-16', {'gift-item-check':item.chooseStatus}]" v-for="(item, index) in giftList" :key="index">
            <Pic :src="item.url" width="80" height="80"/>
            <div class="item-right flex-1 pl-24 df-col df-center" style="height: 80px;">
              <p class="c-text f-nm f-bold mb-4" style="line-height:24px;">{{ item.giftName }}</p>
              <p class="f-md mb-4" style="line-height:16px;">
                <t class="c-title ta-ti" path="xmas_4"></t>
                <Money class="f-medium" :v="item.depositAmount" />
              </p>
              <p class="f-md" style="line-height:16px;">
                <t class="c-title ta-ti" path="xmas_5">Trade</t>
                <Money class="f-medium" :v="item.tradeAmount" />
              </p>
              <Pic src="events/check" v-if="item.chooseStatus" class="check-status" width="24" height="24"/>
            </div>
          </div>
        </div>

        <div class="df-center mt-24 img-btn" style="background-image: url(/img/events/btn_bg@2x.png);">
          <t class="f-bold c-white pt-12" path="xmas_6">My progress</t>
        </div>

        <div class="cur-info df-middle mt-8 px-24 py-16">
          <p class="flex-1">
            <t class="ci-p1 mr-12" path="xmas_7">Trade</t>
            <Money class="f-medium f-nm" #="{ text, origin }" :v="prop('rechargeAmount', data)">
              <ColorText :value="prop('rechargeAmount', data)">{{ text }}</ColorText>
            </Money>
          </p>
          <p class="flex-1">
            <t class="ci-p1 mr-12" path="xmas_8">Trade</t>
            <Money class="f-medium f-nm" #="{ text, origin }" :v="prop('mtTradeAmount', data)">
              <ColorText :value="prop('mtTradeAmount', data)">{{ text }}</ColorText>
            </Money>
          </p>
        </div>
        <div class="py-24">
          <t as="div" path="xmas_9" multiline #="{td}" custom>
            <p class="c-white" v-for="(p, index) in td" :key="index">{{p}}</p>
          </t>
        </div>
      </div>
    </div>
  </PageWithHeader>
</template>
<script>
import { reactive, shallowRef } from '@vue/reactivity'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Pic from '@/components/Pic.vue'
import Money from '@/components.global/Money'
import ColorText from '@/components/ColorText.vue'
import { readRmInfo, readGiftList } from '@/events/christmas/christmas.api'

export default {
  name: 'Christmas',
  components: { PageWithHeader, Pic, Money, ColorText },
  setup () {
    const data = reactive({
      rechargeAmount: 0,
      mtTradeAmount: 0,
    })
    const isWin = shallowRef(false)
    const winGift = shallowRef('')
    const giftList = shallowRef()

    // 获取礼物列表
    const getGiftList = () => {
      readGiftList().then(res => {
        res.forEach(item => {
          if (item.chooseStatus === 1) {
            isWin.value = true
            winGift.value = item.giftName
          }
        })
        giftList.value = res
      })
    }
    getGiftList()

    // 查询充值记录
    const getInfo = () => {
      readRmInfo().then(res => {
        data.rechargeAmount = res.rechargeAmount
        data.mtTradeAmount = res.mtTradeAmount
      })
    }
    getInfo()
    return {
      isWin,
      winGift,
      data,
      giftList,
    }
  },
}
</script>
<style scoped lang="scss">
.main-body{
  background-color: #C72438;
  .main-bg{
    width: 100%;
  }
  .img-btn{
    margin: auto;
    width: 128px;
    height: 36px;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .gifts-wrap{
    margin: auto;
    .gift-item{
      margin: auto;
      margin-bottom: 8px;
      background-image: url(/img/events/gifts_bg@2x.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
      .item-right{
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background: #fff;
      }
      .check-status{
        position: absolute;
        right: 32px;
      }
    }
    .gift-item-check{
      background-image: url(/img/events/chose_bg@2x.png);
    }
  }
  .ad-wrap{
    background-image: url(/img/events/color_border@2x.png);
    background-repeat: no-repeat;
    background-size: 100% 4px;
    background-position: top;
    border-radius: 4px;
    .a-w-top{
      .edit-btn{
        padding: 4px 16px;
        background-color: var(--color-background);
        border-radius: 12px;
      }
    }
  }
  .ad-btn{
    height: 80px;
    padding-top: 14px;
    background-image: url(/img/events/set_btn@2x.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .cur-info{
    background-image: url(/img/events/myprogress_bg@2x.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .ci-p1{
      font-size: 15px;
      font-weight: bold;
      color: #9B3F00;
      line-height: 24px;
    }
  }
}
.ta-ti{
  display: inline-block;
  width: 60px;
}
</style>
